import React, { FC } from 'react';
import { BADInput } from '@bad/components';
import { Col, Row } from '@webteam/layout';
import { UserAndGroupMultiselect } from '../../../../../../../components/rules/users-and-groups-multiselect';
import { FormikErrors } from 'formik';
import { FormikRuleData } from '../index';
import { BasicRuleSubject } from '../../../../../../../api/models/server/rules';

import styles from './popup-pages.module.scss';
import { Mode, mode } from '../../../../../../../shared-interface';

export const DefineUsers: FC<{
  list: BasicRuleSubject[];
  setList: (value: BasicRuleSubject[]) => void;
  errors: FormikErrors<FormikRuleData>;
}> = ({ errors, list, setList }) => {
  let placeholder;
  switch (mode()) {
    case Mode.LV_CLOUD: {
      placeholder = 'Username or group from JetBrains Hub:';
      break;
    }
    case Mode.IDES_CLOUD: {
      placeholder = 'User or group:';
      break;
    }
    case Mode.IDES_ON_PREM: {
      placeholder = 'User or profile:';
      break;
    }
  }
  return (
    <>
      <h3 className="wt-h3 wt-offset-top-24">
        Name the rule and specify <span className={styles.highlighted}>who</span> requests a license
      </h3>
      <Row className="wt-offset-top-24">
        <Col span="auto-fill">
          <BADInput label={<span className="wt-text-2 wt-text-2_hardness_hard">Rule name:</span>} name="name" />
        </Col>
      </Row>
      <Row className="wt-offset-top-24">
        <Col span="auto-fill">
          <span>{placeholder}</span>
          <UserAndGroupMultiselect multiselectError={errors.subjects as string} list={list} setList={setList} />
        </Col>
      </Row>
    </>
  );
};
