import axios, { AxiosError, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import makeRoutes from './make-routes';
import makeHttpClient from './make-http-client';
import { Problem } from '../api/models/problem';

const defaultAxiosInstance = axios.create({});

export enum Mode {
  LV_CLOUD,
  IDES_CLOUD,
  IDES_ON_PREM,
}

const appConfig = {
  httpClient: defaultAxiosInstance,
  routes: makeRoutes(),
  mode: process.env.REACT_APP_IS_EMBEDDED === 'true' ? Mode.IDES_CLOUD : Mode.LV_CLOUD,
};

interface AppInitConfigParams {
  routesPrefix?: string;
  requestInterceptor?: (config: InternalAxiosRequestConfig) => InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>;
  errorResponseInterceptor?: (error: AxiosError) => Promise<Problem>;
  mode: Mode;
}

export function configureApp({ routesPrefix, requestInterceptor, errorResponseInterceptor, mode }: AppInitConfigParams) {
  appConfig.httpClient = makeHttpClient(requestInterceptor, errorResponseInterceptor);
  appConfig.routes = makeRoutes(routesPrefix || '');
  appConfig.mode = mode;
}

export function client() {
  return appConfig.httpClient;
}

export function routes() {
  return appConfig.routes;
}

export function mode() {
  return appConfig.mode;
}
