import React, { FC, useState } from 'react';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { Mode, mode, routes } from '../../../../../../shared-interface';
import { Col, Row } from '@webteam/layout';
import { LoadingIcon } from '@webteam/icons';
import { Select } from '@webteam/select';
import { Tab, TabList } from '@webteam/tab-list';
import { BADButton, BADPopup } from '@bad/components';
import {
  BaseAccessRuleDto,
  BasicRuleProduct,
  BasicRuleSubject,
  IdentifiableProduct,
  IdentifiableSubject,
  OverridenRuleType,
  ProductAccess,
  RuleEntry,
  RuleSubjectType,
  TestRuleData,
  TestRuleResult,
} from '../../../../../../api/models/server/rules';
import { UserAndGroupsSelect } from '../../../../../../components/rules/user-and-groups-select';
import api from '../../../../../../api/routes';

import styles from './test-rule.module.scss';
import { isEmbedded } from '../../../../../../common';

export const TestRulePopup: FC<{ onRequestClose: () => void }> = ({ onRequestClose }) => {
  const [subjectSelectValue, setSubjectSelectValue] = useState<BasicRuleSubject | undefined>();
  const [productSelectValue, setProductSelectValue] = useState<{ label: string; value: string } | undefined>();
  const [testData, setTestData] = useState<{ response: TestRuleResult; data: TestRuleData } | undefined>();
  return (
    <BADPopup
      className={classnames(styles.popupHeader, styles.popup)}
      header={<>Test Rules</>}
      size="m"
      buttonSize="m"
      alignButton="end"
      hidePrimaryAction={true}
      closeActionLabel={<>Close</>}
      isOpen={true}
      onRequestClose={onRequestClose}
      isReversedButtons={false}
    >
      {() => (
        <TestRuleContent
          testData={testData}
          setTestData={setTestData}
          subjectSelectValue={subjectSelectValue}
          productSelectValue={productSelectValue}
          setSubjectSelectValue={setSubjectSelectValue}
          setProductSelectValue={setProductSelectValue}
        />
      )}
    </BADPopup>
  );
};

enum TabIndex {
  Result = 'Result',
  RelatedRules = 'Related Rules',
}

export const TestRuleContent: FC<{
  subjectSelectValue: BasicRuleSubject | undefined;
  productSelectValue: { label: string; value: string } | undefined;
  setSubjectSelectValue: (el: BasicRuleSubject | undefined) => void;
  setProductSelectValue: (el: { label: string; value: string } | undefined) => void;
  testData: { response: TestRuleResult; data: TestRuleData } | undefined;
  setTestData: (el: { response: TestRuleResult; data: TestRuleData } | undefined) => void;
  ruleId?: string;
  rule?: BaseAccessRuleDto<BasicRuleSubject | IdentifiableSubject, BasicRuleProduct | IdentifiableProduct>;
}> = ({ testData, setTestData, subjectSelectValue, productSelectValue, setSubjectSelectValue, setProductSelectValue, ruleId, rule }) => {
  const [activeIndex, setActiveIndex] = useState<TabIndex>(TabIndex.Result);
  const [loading, setLoading] = useState(false);
  const [invalidField, setInvalidField] = useState(false);
  const [error, setError] = useState<string>();
  const testProduct = api.server.rules.useTestProduct();

  const handleSubmit = () => {
    setError('');
    setTestData(undefined);
    setInvalidField(false);
    if (productSelectValue && subjectSelectValue) {
      const subject = subjectSelectValue;
      const product = productSelectValue && testProduct.data?.find((el) => el.type === productSelectValue.value && el.name === productSelectValue.label);
      if (subject && product) {
        const data: TestRuleData = {
          currentRule: rule,
          testSubject: subject,
          testProduct: product,
          currentEditedId: ruleId,
        };
        setLoading(true);
        api.server.rules
          .testRule(data)
          .then((response) => {
            setTestData({ data, response: response.data });
          })
          .catch((error) => {
            setError(error.detail);
          })
          .finally(() => setLoading(false));
      }
    } else {
      setInvalidField(true);
    }
  };
  let invalidMessage;
  switch (mode()) {
    case Mode.LV_CLOUD: {
      invalidMessage = 'Configure Username or group from JetBrains Hub and a product to check effective permissions';
      break;
    }
    case Mode.IDES_CLOUD: {
      invalidMessage = 'Configure user or group and a product to check effective permissions';
      break;
    }
    case Mode.IDES_ON_PREM: {
      invalidMessage = 'Configure user or profile and a product to check effective permissions';
      break;
    }
  }
  return (
    <>
      <Row className="wt-offset-top-24" size="s">
        <Col span={6} className="wt-col-sm-12">
          <UserAndGroupsSelect value={subjectSelectValue} setValue={setSubjectSelectValue} />
        </Col>
        <Col span={6} className="wt-col-sm-12">
          <Select
            className={classnames(!productSelectValue && styles.productSelectPlaceholder)}
            value={productSelectValue}
            isSearchable={true}
            onChange={(value) => setProductSelectValue(value)}
            options={
              testProduct.data
                ? testProduct.data
                    .filter((el) => el.code !== 'anyPlugin' && el.code !== 'anyJbProduct')
                    .map((el) => {
                      return { label: el.name, value: el.type };
                    })
                : []
            }
            placeholder="Product"
          />
        </Col>
      </Row>
      <Row direction="row-reverse" className="wt-offset-top-12">
        <Col span="inline">
          <BADButton size="s" mode="outline" onClick={handleSubmit}>
            Check effective permissions
          </BADButton>
        </Col>
      </Row>
      {testData && (
        <Row className="wt-offset-top-16">
          <Col span="inline">
            <TabList value={activeIndex} onChange={(v) => setActiveIndex(v as TabIndex)}>
              <Tab className={styles.tab} value={TabIndex.Result}>
                Effective Permissions
              </Tab>
              <Tab className={styles.tab} value={TabIndex.RelatedRules}>
                Contributing Rules
              </Tab>
            </TabList>
          </Col>
        </Row>
      )}
      <Row className="wt-offset-top-16">
        <Col span="auto-fill">
          {invalidField && <div className="wt-text-2 wt-text-2_hardness_pale">{invalidMessage}</div>}
          {loading && <LoadingIcon />}
          {testData ? <ContentSwitcher tab={activeIndex} testData={testData} /> : error && <div className={classnames(styles.error, 'wt-text-2')}>{error}</div>}
        </Col>
      </Row>
      <div className="wt-offset-top-48" />
    </>
  );
};

const ContentSwitcher: FC<{ tab: TabIndex; testData: { response: TestRuleResult; data: TestRuleData } }> = ({ tab, testData }) => {
  const testProduct = testData.data.testProduct;
  const testSubject = testData.data.testSubject;
  const result = testData.response.result;
  const resultOutcome = result.outcome === ProductAccess.Permitted;
  const currentRuleEntry = testData.response.currentRuleEntry;
  const otherRules = testData.response.otherRules;
  switch (tab) {
    case TabIndex.Result:
      return (
        <span className={classnames(resultOutcome ? styles.enabledUser : styles.disabledUser)}>
          {testSubject.subjectType === RuleSubjectType.Group ? 'Members of ' : ''}
          <span className={styles.boldText}>{testSubject.name} </span>
          {`${resultOutcome ? 'can' : 'can’t'} use ${testProduct.name}`}
        </span>
      );
    case TabIndex.RelatedRules:
      return (
        <>
          {currentRuleEntry && (
            <Row>
              <Col span={3}>
                <span className={classnames(styles.ruleName, styles.currentRuleName)}>{currentRuleEntry.name}</span>
                <div className={classnames('wt-text-3 wt-text-3_hardness_hard', styles.current)}>Current</div>
              </Col>
              <Col span={9}>
                {currentRuleEntry.participatedSubjects.length > 0 ? (
                  <RelatedRulesContent rule={currentRuleEntry} subject={testSubject} product={testProduct} />
                ) : (
                  <span className="wt-text-2 wt-text-2_hardness_pale">No selected user and product mentioned</span>
                )}
              </Col>
            </Row>
          )}
          {currentRuleEntry?.id !== result.id && result.id > 0 && (
            <Row className={classnames(currentRuleEntry && 'wt-offset-top-12')}>
              <Col span={3}>
                <NavLink to={routes().admin.rules.tabs.list.edit(result.id)} target="_blank" className="wt-link">
                  <span className={styles.ruleName}>{result.name}</span>
                </NavLink>
              </Col>
              <Col span={9}>
                <RelatedRulesContent rule={result} product={testProduct} subject={testSubject} />
              </Col>
            </Row>
          )}
          {otherRules.length > 0
            ? otherRules.map((el, i) => {
                return (
                  <Row key={JSON.stringify([el.id, i])} className={classnames((currentRuleEntry || result.id > 0 || i !== 0) && 'wt-offset-top-12')}>
                    <Col span={3}>
                      <NavLink to={routes().admin.rules.tabs.list.edit(el.id)} target="_blank" className="wt-link">
                        <span className={styles.ruleName}>{el.name}</span>
                      </NavLink>
                    </Col>
                    <Col span={9}>
                      <RelatedRulesContent rule={el} product={testProduct} subject={testSubject} />
                    </Col>
                  </Row>
                );
              })
            : !currentRuleEntry && result.id < 0 && <span className="wt-text-2 wt-text-2_hardness_pale">There's no rule mentioning selected username and a product</span>}
        </>
      );
  }
};

const RelatedRulesContent: FC<{ rule: RuleEntry; subject: BasicRuleSubject; product: BasicRuleProduct }> = ({ rule, subject, product }) => {
  const result = rule.outcome === ProductAccess.Permitted;
  return (
    <>
      <p className={classnames(result ? styles.enabledUser : styles.disabledUser, rule.overriddenBy === OverridenRuleType.Disabled && styles.disableNameRule)}>
        {rule.participatedSubjects.length > 0 ? (
          rule.participatedSubjects.map((el) => (
            <span key={JSON.stringify([el.name, el.subjectType])}>
              {el.subjectType === RuleSubjectType.Group ? (isEmbedded() ? 'Profile' : 'Group') : 'User'} <span className={styles.boldText}> {el.name} </span>
            </span>
          ))
        ) : (
          <>
            {subject.subjectType === RuleSubjectType.Group ? (isEmbedded() ? 'Profile' : 'Group') : 'User'} <span className={styles.boldText}> {subject.name} </span>
          </>
        )}
        <span className={styles.boldText}>
          {rule.participatedSubjects.length > 0 && !rule.participatedSubjects.find((el) => el.name === subject.name && el.subjectType === subject.subjectType) && ` (incl. ${subject.name}) `}
        </span>
        {`${result ? 'can' : 'can’t'} use ${product.name} ${rule.packs.length > 0 ? ` (via ${rule.packs.map((el) => el).join(', ')})` : ''}`}
      </p>
      {rule.overriddenBy && <span className="wt-text-3 wt-text-3_hardness_pale">{rule.overriddenBy === OverridenRuleType.Disabled && 'Rule disabled'}</span>}
    </>
  );
};
