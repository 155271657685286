import 'reset-css/reset.css';
import '@webteam/typography';
import '@webteam/colors/lib/index.css';
import '@webteam/table/lib/styles';

import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import App from './App';
import './common/configure-dayjs';
import { configureApp, Mode } from './shared-interface';
import { defaultErrorResponseInterceptor } from './api/interceptors';
import './main.css';

configureApp({
  errorResponseInterceptor: defaultErrorResponseInterceptor,
  mode: Mode.LV_CLOUD,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
