import React, { FC, useMemo, useState } from 'react';
import { LoadingIcon, PersonIcon, TeamIcon } from '@webteam/icons';
import { List, ListItem } from '@webteam/list';
import { BADInput, Underlayer } from '@bad/components';
import { BasicRuleSubject, RuleSubjectType } from '../../../api/models/server/rules';
import api from '../../../api/routes';

import styles from './user-and-group-select.module.scss';
import { Mode, mode } from '../../../shared-interface';

export const UserAndGroupsSelect: FC<{ value: BasicRuleSubject | undefined; setValue: (el: BasicRuleSubject | undefined) => void }> = ({ value, setValue }) => {
  const [searchValue, setSearchValue] = useState<string>(value ? value.name : '');
  const [isFocus, toggleFocus] = useState(false);
  const subject = api.server.rules.useSubject(searchValue);
  const loading = !subject.data && !subject.error;
  const options = useMemo(() => (subject.data ? subject.data.filter((el) => !value || (value && el.name !== value.name && el.subjectType !== value.subjectType)) : []), [subject.data, value]);
  let placeHolder;
  switch (mode()) {
    case Mode.LV_CLOUD: {
      placeHolder = 'Username or group from JetBrains Hub';
      break;
    }
    case Mode.IDES_CLOUD: {
      placeHolder = 'User or group';
      break;
    }
    case Mode.IDES_ON_PREM: {
      placeHolder = 'User or profile';
      break;
    }
  }
  return (
    <>
      {isFocus && <Underlayer zIndex={0} onClick={() => toggleFocus(false)} makeVisible={false} isShown={true} />}
      <div className={styles.select}>
        <BADInput
          className={styles.input}
          onFocus={() => toggleFocus(true)}
          icon={value ? value.subjectType === RuleSubjectType.Group ? <TeamIcon /> : <PersonIcon /> : undefined}
          iconPosition={'left'}
          value={searchValue}
          onChange={(e) => {
            setValue(undefined);
            setSearchValue(e.target.value);
          }}
          onClear={() => {
            setValue(undefined);
            setSearchValue('');
          }}
          placeholder={placeHolder}
        />
        {isFocus && (subject.data || loading) && searchValue.length > 0 && (
          <div className={styles.options}>
            <List>
              {loading && !value ? (
                <ListItem size="m" icon={<LoadingIcon />} />
              ) : options.length > 0 ? (
                options.map((el, i) => {
                  const Icon = el.subjectType === RuleSubjectType.Group ? TeamIcon : PersonIcon;
                  return (
                    <ListItem
                      icon={<Icon className={styles.icon} />}
                      key={JSON.stringify([el.name, i])}
                      size="m"
                      onClick={() => {
                        setValue(el);
                        toggleFocus(false);
                        setSearchValue(el.name);
                      }}
                    >
                      {el.name}
                    </ListItem>
                  );
                })
              ) : (
                <ListItem disabled={true}>Not found</ListItem>
              )}
            </List>
          </div>
        )}
      </div>
    </>
  );
};
