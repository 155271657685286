import React, { FC, useState } from 'react';
import { FormikHelpers } from 'formik';
import classnames from 'classnames';
import { BADPopup } from '@bad/components';
import { UserAndGroupMultiselect } from '../../../../../../components/rules/users-and-groups-multiselect';
import { BasicRuleSubject } from '../../../../../../api/models/server/rules';
import api from '../../../../../../api/routes';

import styles from '../../priorities.module.scss';
import { Mode, mode } from '../../../../../../shared-interface';

export const EditPrioritizedUsersPopup: FC<{ onRequestClose: () => void }> = ({ onRequestClose }) => {
  const priorities = api.server.rules.priorities.usePriorities();
  const [error, setError] = useState();
  const [tagsList, setTagsList] = useState<BasicRuleSubject[]>(priorities.data ? priorities.data.map((el) => el.subject) : []);
  const onSubmit = (values: object, { setSubmitting }: FormikHelpers<object>) => {
    const added = tagsList
      .filter((tag) => !priorities.data?.some((el) => el.subject.subjectType === tag.subjectType && el.subject.name === tag.name))
      .map((el) => {
        return { subject: el };
      });
    const deleted = priorities.data?.filter((el) => !tagsList.some((tag) => el.subject.subjectType === tag.subjectType && el.subject.name === tag.name));
    api.server.rules.priorities
      .updatePriorities(added, deleted)
      .then(() => {
        priorities.mutate().then(() => onRequestClose());
      })
      .catch((error) => {
        setError(error.detail);
        setSubmitting(false);
      });
  };
  let headerMessage: string;
  switch (mode()) {
    case Mode.LV_CLOUD: {
      headerMessage = 'Username or group from JetBrains Hub:';
      break;
    }
    case Mode.IDES_CLOUD: {
      headerMessage = 'User or group:';
      break;
    }
    case Mode.IDES_ON_PREM: {
      headerMessage = 'User or profile:';
      break;
    }
  }
  return (
    <BADPopup
      className={styles.popup}
      header={<>Edit Prioritized Users</>}
      primaryActionLabel={<>Save</>}
      closeActionLabel={<>Discard Changes</>}
      onRequestClose={onRequestClose}
      isOpen={true}
      formikConfig={{ initialValues: {}, onSubmit }}
      size="m"
    >
      {() => (
        <>
          <p className="wt-offset-top-24">
            If a prioritized user requests a license when all of them are taken, License Vault revokes and transfers a license from a non-prioritized user.{' '}
            <a className="wt-link" target={'_blank'} href={'https://jb.gg/lv-rules-prioritized'} rel={'noreferrer'}>
              Learn more
            </a>
          </p>
          <div className="wt-offset-top-24">
            <span>{headerMessage}</span>
            <UserAndGroupMultiselect
              list={tagsList}
              setList={(updateList) => {
                setTagsList(updateList);
              }}
            />
          </div>

          {error && <p className={classnames('wt-text-3 wt-offset-top-12', styles.error)}>{error}</p>}
        </>
      )}
    </BADPopup>
  );
};
